












































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Getter, Action } from 'vuex-class'
import globalMixins from '@/mixins/global.ts'

@Component({
  mixins: [globalMixins]
})
export default class Login extends Vue<globalMixins> {
  @Getter('account') account: Object
  @Getter('token') token: string
  @Getter('projects') projects: Object[]
  @Getter('otherProjects') otherProjects: Object[]
  @Action('login') login: Function
  
  loginPage: object[] = [{
    bgColor: 'page-title-one',
    contentBgColor: 'page-content-one',
    leftUrl: require('@/assets/images/login/1.png'),
    name: 'pageOne',
    value: 'pageOne'
  }, {
    bgColor: 'page-title-two',
    contentBgColor: 'page-content-two',
    leftUrl: require('@/assets/images/login/duli.png'),
    name: '独立',
    value: 'pageTwo',
    lis: ['各个数据库项目权限独立', '个性化配置病例模板']
  }, {
    bgColor: 'page-title-three',
    contentBgColor: 'page-content-three',
    leftUrl: require('@/assets/images/login/guanli.png'),
    name: '管理',
    value: 'pageThree',
    lis: ['规范管理流程', '有效的组织病例的采集', '结构化病例数据']
  }, {
    bgColor: 'page-title-four',
    contentBgColor: 'page-content-four',
    leftUrl: require('@/assets/images/login/quanxian.png'),
    name: '权限',
    value: 'pageFour',
    lis: ['三级权限管理', '严格限制数据访问', '支持图格式数据']
  }]
  checked: Boolean = true
  LoginForm: Object = {
    account: '',
    password: '',
    projects: []
  }
  logining: Boolean = false
  rule: object = {
    account: [
      {
        required: true,
        max: 20,
        min: 6,
        message: '用户名是必须的，长度为6-20位',
        trigger: 'blur'
      }
    ],
    password: [
      {
        required: true,
        message: '密码是必须的！',
        trigger: 'blur'
      }
    ]
  }
  mounted () {
    // this.getCookie()
  }
  submit () {
    let LoginForm = this.$refs.LoginForm as HTMLFormElement
    if (LoginForm) {
      LoginForm.validate((valid: boolean) => {
      if (valid) {
        this.logining = true
        let params = {
          account: (this.LoginForm as any).account,
          password: (this.LoginForm as any).password,
          projects: (this.LoginForm as any).projects
        }
        this.login({ params }).then(() => {
          this.logining = false
          this.successMsg('登录成功！')
          let account = this.account
          let token = this.token
          this.logining = false
            if (this.checked) {
              this.setCookie((this.LoginForm as any).account, 7)
            } else {
              this.clearCookie()
            }
            this.setCookie((this.LoginForm as any).account, 7)
            localStorage.setItem('account', JSON.stringify(account))
            localStorage.setItem('token', token)
            // localStorage.setItem('projects', JSON.stringify(projects[0]))
            this.$router.push('/topic-add')
        }).catch((error: any) => {
          this.logining = false
        })
      } else {
        this.logining = false
      }
    })
    }
  }

  setCookie (account: string, days: any) {
    let date = new Date()
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days) // 保存的天数
    // 字符串拼接cookie
    window.document.cookie = 'account' + '=' + account + ';path=/;expires=' + date.toUTCString()
  }
    
  getCookie () {
    if (document.cookie.length > 0) {
      let arr = document.cookie.split('; ') // 显示的格式需要切割一下自己可输出看下
      for (let i = 0; i < arr.length; i++) {
        let arr2 = arr[i].split('=') // 再次切割
        if (arr2[0] === 'account') { // 判断查找相对应的值
          (this.LoginForm as any).account = arr2[1] // 保存到保存数据的地方
        }
      }
    }
  }

  clearCookie () { // 清除cookie
    this.setCookie('', -1) // 修改2值都为空，天数为负1天就好了
  }

  reset () {
    (<HTMLFormElement>this.$refs.LoginForm).resetFields()
  }
}
